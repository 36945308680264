<template>
  <div class="d-footer"></div>
</template>

<script>
export default {
  name: "Footer",
  components: {}
}
</script>

<style scoped lang="scss">
@import "@/styles/base/_variables.scss";

</style>
